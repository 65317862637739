<template>
    <div class="mapbox rounded-lg overflow-hidden h-64">
        <map-box-marker :address="address" :map="map" v-if="map"/>
    </div>
</template>

<script>
    import mapboxgl from 'mapbox-gl/dist/mapbox-gl.js';

    export default {
        props: {
            address: {
                required: true,
                type: Object,
            },
        },

        data() {
            return {
                map: null,
            }
        },

        mounted() {
            this.initMap();
        },

        methods: {
            initMap() {
                mapboxgl.accessToken = 'pk.eyJ1Ijoic3RlcGhhbmRldiIsImEiOiJjazBiaXNzdG0wc2N2M21tZDlkMmd1ejVsIn0.1IJ5Cwa1OdRQZFthqhSAVA';

                this.map = new mapboxgl.Map({
                    attributionControl: false,
                    center: [this.address.lng, this.address.lat],
                    container: this.$el,
                    style: 'mapbox://styles/mapbox/streets-v11?optimize=true',
                    zoom: 14,
                });

                // Add zoom and rotation controls to the map.
                this.map.addControl(new mapboxgl.NavigationControl());
            },
        }
    };
</script>

<style>
    @import 'mapbox-gl/dist/mapbox-gl.css';
</style>
