<template>
    <span>
        <i class="fa-solid fa-star fa-lg text-yellow-400" v-for="(star, key) in full"/>

        <i class="fa-regular fa-star-half-stroke fa-lg text-yellow-400" v-if="half"/>

        <i class="fa-solid fa-star fa-lg text-gray-200" v-for="(star, key) in remainder"/>
    </span>
</template>

<script>
    export default {
        props: {
            rating: {
                required: true,
                type: Number
            }
        },

        computed: {
            full() {
                return Math.floor(this.rating);
            },

            half() {
                return this.rating % 1 !== 0;
            },

            remainder() {
                return 5 - Math.ceil(this.rating);
            }
        }
    };
</script>
