<template>
    <div class="w-full">
        <transition enter-active-class="animate__animated animate__slideInRight" leave-active-class="animate__animated animate__slideOutRight">
            <nav class="w-full h-full top-0 left-0 bg-gradient-to-br from-[#E8E795] to-[#88BA59] lg:bg-none fixed lg:static flex items-center dark:text-white" v-if="visible">
                <slot/>
            </nav>
        </transition>

        <div class="hamburger flex align-items-center items-center lg:hidden cursor-pointer" @click="toggle">
            <h3 v-if="!visible" class="-mr-4 cursor-pointer text-2xl text-emperor">menu</h3>

            <svg width="100px" height="100px" viewBox="0 0 1000 1000" :class="classes">
                <path id="pathA" d="M 300 400 L 700 400 C 900 400 900 750 600 850 A 400 400 0 0 1 200 200 L 800 800" :class="stroke"/>
                <path id="pathB" d="M 300 500 L 700 500" :class="stroke"/>
                <path id="pathC" d="M 700 600 L 300 600 C 100 600 100 200 400 150 A 400 380 0 1 1 200 800 L 800 200" :class="stroke"/>
            </svg>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                visible: false,
                open: false,
                close: false,
                animationDuration: 400
            }
        },

        created() {
            const mediaQuery = window.matchMedia('(min-width: 1024px)');

            // Initial check.
            this.visible = mediaQuery.matches;

            // Listener for resizing.
            mediaQuery.onchange = ((e) => {
                this.visible = e.matches;
            });
        },

        methods: {
            toggle() {
                this.visible = !this.visible;
                this.toggleBodyScroll(this.visible);
            },

            toggleBodyScroll(visible) {
                const html = document.querySelector('html').classList;

                visible ? html.add('no-scroll') : html.remove('no-scroll');
            }
        },

        watch: {
            visible(value) {
                const key = value ? 'open' : 'close';

                this[key] = true;

                setTimeout(() => {
                    this[key] = false;
                }, this.animationDuration);
            }
        },

        computed: {
            classes() {
                return {
                    'visible': this.visible,
                    'animation-open': this.open,
                    'animation-close': this.close,
                }
            },

            stroke() {
                return {
                    'stroke-emperor': !this.visible,
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    .animate__animated {
        --animate-duration: .4s;
    }

    svg {
        margin-right: -15px;
    }

    .visible {
        .hamburger {
            z-index: 5;

            path {
                stroke: white !important;
            }

            #pathA { stroke-dasharray: 2901.57, 5258.15, 240; }
            #pathB { stroke-dasharray: 400, 600, 0; }
            #pathC { stroke-dasharray: 3496.56, 6448.11, 240; }
        }
    }

    .animation-open {
        #pathA { animation: pathA 500ms ease-in-out forwards; }
        #pathB { animation: pathB 500ms ease-in-out forwards; }
        #pathC { animation: pathC 500ms ease-in-out forwards; }
    }

    .animation-close {
        #pathA { animation: pathA 500ms ease-in-out reverse; }
        #pathB { animation: pathB 500ms ease-in-out reverse; }
        #pathC { animation: pathC 500ms ease-in-out reverse; }
    }

    .hamburger {
        position: absolute;
        top: 20px;
        right: 0;
        transform: translateY(calc(-50% + 1rem));
        z-index: 3;
        overflow: hidden;

        path {
            stroke: #fff;
            stroke-width: 40px;
            stroke-linecap: round;
            stroke-linejoin: round;
            fill: transparent;
        }

        #pathA {
            stroke-dashoffset: 5803.15;
            stroke-dasharray: 2901.57, 2981.57, 240;
        }

        #pathB {
            stroke-dashoffset: 800;
            stroke-dasharray: 400, 480, 240;
        }

        #pathC {
            stroke-dashoffset: 6993.11;
            stroke-dasharray: 3496.56, 3576.56, 240;
        }
    }

    @keyframes pathA {
        0% { stroke-dasharray: 2901.57, 2981.57, 240; }
        80% { stroke-dasharray: 2901.57, 5358.15, 240; }
        100% { stroke-dasharray: 2901.57, 5258.15, 240; }
    }

    @keyframes pathB {
        from { stroke-dasharray: 400, 480, 240; }
        to { stroke-dasharray: 400, 600, 0; }
    }

    @keyframes pathC {
        0% { stroke-dasharray: 3496.56, 3576.56, 240; }
        80% { stroke-dasharray: 3496.56, 6548.11, 240; }
        100% { stroke-dasharray: 3496.56, 6448.11, 240; }
    }
</style>
