<template>
    <div class="w-full h-64 w-full">
        <canvas id="acquisitions"></canvas>
    </div>
</template>

<script>
    import { Chart, TimeScale, LineController, LinearScale, PointElement, LineElement, Filler } from 'chart.js';
    import { nl } from 'date-fns/locale';
    import 'chartjs-adapter-date-fns';

    // Custom.
    import ChartGradient from './ChartGradient';
    import DashedLinePlugin from './DashedLinePlugin';

    Chart.register([
        TimeScale,
        LineController,
        LinearScale,
        PointElement,
        LineElement,
        Filler,
    ]);

    export default {
        props: {
            prices: {
                type: Array,
                required: true,
            },
        },

        mounted() {
            const canvas = document.getElementById('acquisitions');

            const data = {
                datasets: [{
                    data: this.mappedPrices,
                    backgroundColor : ChartGradient(canvas),
                    fill: true,
                    borderColor: 'rgb(216,189,126)',
                    tension: 0.3,
                    // pointStyle: new Image(),
                }]
            };

            const config = {
                type: 'line',
                data,
                options: {
                    maintainAspectRatio: false,
                    scales: {
                        y: {
                            ticks: {
                                // Include a dollar sign in the ticks
                                callback: (value) => new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(value),
                                maxTicksLimit: 10,
                            },
                            grace: '100%',
                        },
                        x: {
                            adapters: {
                                date: {
                                    locale: nl,
                                }
                            },
                            type: 'time',
                            time: {
                                unit: 'month'
                            },
                        },
                    },
                },
                plugins: [DashedLinePlugin(canvas)],
            };

            new Chart(
                canvas,
                config,
            );
        },

        computed: {
            mappedPrices() {
                return Object
                    .keys(this.prices)
                    .map((created_at) => ({ x: created_at, y: this.prices[created_at] / 100 }));
            }
        }
    }
</script>
