export default function dashedLinePlugin(canvas) {
    const ctx = canvas.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, 'rgb(250, 237, 188)');
    gradient.addColorStop(1, 'rgba(250, 237, 188, .3)');

    return {
        id: 'corsair',
        defaults: {
            width: 1,
            color: '#525252',
            dash: [3, 3],
        },
        afterInit: (chart) => {
            chart.corsair = {
                x: 0,
                y: 0,
            }
        },
        afterEvent: (chart, args) => {
            const {inChartArea} = args
            const { x,y } = args.event

            chart.corsair = { x, y, draw: inChartArea }
            chart.draw()
        },
        afterDatasetsDraw: (chart, args, opts) => {
            const { ctx } = chart
            const { top, bottom } = chart.chartArea
            const { x, draw } = chart.corsair

            if (!draw) return

            ctx.save()

            ctx.beginPath()
            ctx.lineWidth = opts.width
            ctx.strokeStyle = opts.color
            ctx.setLineDash(opts.dash)
            ctx.moveTo(x, bottom)
            ctx.lineTo(x, top)
            ctx.stroke()

            ctx.restore()
        }
    }
};
