<template>
    <div class="marker">
        <svg-hop-marker class="marker-icon" ref="marker"/>

        <div class="popup" ref="popup">
            {{ address.line1 }} {{ address.postal_code }} {{ address.region }}
        </div>
    </div><!-- /.marker -->
</template>

<script>
import mapboxgl from 'mapbox-gl/dist/mapbox-gl.js';
import SvgHopMarker from './SvgHopMarker.vue';

export default {
    components: { SvgHopMarker },

    data() {
        return {
            marker: null
        }
    },

    props: {
        address: {
            required: true,
            type: Object,
        },

        map: {
            required: true,
            type: Object,
        },
    },

    mounted() {
        this.marker = new mapboxgl.Marker(this.options);
        this.marker.setLngLat([this.address.lng, this.address.lat]);
        this.marker.setPopup(this.popup);
        this.marker.addTo(this.map);
    },

    methods: {
        /**
         * Because the DOM element is being manipulated by the MapBox library we need to
         * clone it so that it is not removed/manipulated from the template of this component.
         */
        clone() {
            return this.$refs.marker.$el
        }
    },

    computed: {
        options() {
            return {
                anchor: 'bottom',
                element: this.clone()
            }
        },

        popup() {
            return new mapboxgl.Popup({ offset: 25 }).setHTML(this.$refs.popup.innerHTML);
        },
    },

    beforeDestroy() {
        this.marker.remove();
    }
}
</script>

<style lang="scss" scoped>
    .marker {
        display: none;
    }

    a {
        color: #525252;
    }

    .marker-icon {
        cursor: pointer;
        height: 40px;
        width: 40px;
    }

    .title {
        font-family: 'Signika', sans-serif;
        line-height: 1.3;
        font-size: 0.85rem;
    }

    img {
        max-height: 4rem;
        max-width: 4rem;
    }
</style>
